
html {
  color: #fff;
  font-size: 120%;
  font-family: 'Zen Dots', cursive;
}

.bg {
  background: url(img/bg.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scrollbar-color: rgb(70, 70, 70) rgb(30, 30, 30);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast {
  font-family: 'Macondo', cursive;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(30, 30, 30);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(70, 70, 70);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1e8fff;
}

div {
  outline: none;
}

